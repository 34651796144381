import { Component, Input } from '@angular/core';
import { Sport } from 'src/app/Types/Response/SportResponse';

@Component({
  selector: 'sport-item',
  templateUrl: './sport-item.component.html',
  styleUrls: ['./sport-item.component.scss']
})
export class SportItemComponent {
  isExpanded : Boolean  = false ;
  @Input() sports : Sport |null = null  ;

  messageMe(){
    this.isExpanded = !this.isExpanded ;
  }
  
}
