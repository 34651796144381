<div [@toggleNav]="NavOpen ? 'open' : 'closed'" class="sportingList">
    <fa-icon [icon]="faClose" size="2x" class="font-awesome-icons" id="close-icon" (click)="toggleNav()"></fa-icon>
    <sport-list></sport-list>
</div>
<fa-icon [icon]="faBars" size="2x" class="font-awesome-icons" id="burger-menu" (click)="toggleNav()"></fa-icon>
<div class="bet-submit-toaster" *ngIf="ShowBetToaster" @toggleBetToasterIn @toggleBetToasterOut [ngClass]="(ShowBetToaster ? 'show-bet-confirmation' : 'hide-bet-confirmation') + ' ' + (IsBetSuccess ? 'bet-submit-success' : 'bet-submit-failed')">
    <fa-icon [icon]="IsBetSuccess ? faCheck : faTimes" size="3x" class="fa-check-icon"></fa-icon>
    <span class="submit-bet-text">{{this.BetStatusTitle}}
      <br>
      <span>{{this.BetStatusDescription}}</span>
    </span>
</div>
<iframeloader-util></iframeloader-util>

<app-betslip-floating (toggleBetslip)="onToggleBetslip()"></app-betslip-floating>
<div class="betslip-comp">
    <app-betslip [showBetslip]="showBetslip"></app-betslip>
</div>
