import { Component, Input } from '@angular/core';
import { trigger , state , style , transition , animate } from '@angular/animations';
import { faClose, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SportcastServiceService } from './Service/sportcast.service.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations:[
    trigger('toggleNav' , [

      state("open",style({
        left : '0px'
      })),
      state("closed" , style({
        left: '-100vw'
      })),
      transition('open => closed',[
        animate('0.2s')
      ]),
      transition('closed => open',[
        animate('0.2s')
      ])

    ]),
    trigger('toggleBetToasterIn' , [
      transition(':enter',[
        style({
          opacity: 0
        }),
        animate('1s ease-in', style({
          opacity: 1
        }))
      ])
    ]),

    trigger('toggleBetToasterOut' , [
      transition(':leave',[
        animate('1s ease-out', style({
          opacity: 0
        }))
      ])

    ])
  ]
})

export class AppComponent {
  faCheck = faCheck;
  faTimes = faTimes;
  faClose = faClose;
  faBars = faBars;
  title = 'sportcast-widget';
  @Input() NavOpen = true;
  @Input() ShowBetToaster = false;
  @Input() IsBetSuccess = true;
  @Input() BetStatusTitle = "";
  @Input() BetStatusDescription = "";

  showBetslip: boolean = false;

  constructor(
    private eventEmitterService : SportcastServiceService
  ){}

  ngOnInit()
  {
      this.eventEmitterService.sportToggleSub = this.eventEmitterService.sportToggle.subscribe(() =>{
        this.toggleNav();
      });
  }

  toggleNav() {
    this.NavOpen = !this.NavOpen;
  }

  ToggleBetToaster(data?: any)
  {
    if(data != undefined)
    {
      this.IsBetSuccess = data.status;
      this.BetStatusTitle = data.title;
      this.BetStatusDescription = data.description;
    }

    this.ShowBetToaster = !this.ShowBetToaster;

    if(this.ShowBetToaster)
    {
      setTimeout(() => {this.ToggleBetToaster();}, 5000);
    }
  }

  onToggleBetslip(): void {
    this.showBetslip = !this.showBetslip;
  }

}
