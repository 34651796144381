<div (click)="messageMe()" class="sport-item-container">
    <div class="sport-name">{{sports?.name}}</div>
    <div *ngIf="!isExpanded; then openBlock else closeBlock" class="sport-dd-ico"></div>

    <ng-template #openBlock>
        <div class="sport-dd-ico">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
        </div>
    </ng-template>

    <ng-template #closeBlock>
        <div class="sport-dd-ico">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
        </div>
    </ng-template>


</div>
<div *ngIf="isExpanded; then showBlock else hideBlock" class="sport-to-league-relation">
</div>
<ng-template #showBlock>
    <league-list [SportId]="sports?.id" [SportName]="sports?.name">

    </league-list>
</ng-template>
<ng-template #hideBlock>

</ng-template>
