<div class="bet-container">
  <i class="font-awesome-icon-parent"><fa-icon [icon]="faClose" size="1x" id="close-icon" class="font-awesome-icons" (click)="RemoveBet()"></fa-icon></i>
  <div class="bet-info">
    <div class="info-title">Fixture Name:</div>
    <div id="Fixture" class="info-value">{{ this.bet.FixtureName }}</div>
  </div>
  <div class="bet-info">
    <div class="info-title">Description:</div>
    <div id="Description" class="info-value">{{ this.bet.Description }}</div>
  </div>
  <div class="bet-info">
    <div class="info-title">Odds (Stake Included):</div>
    <div id="Odds" class="info-value">{{ this.bet.Odds }}</div>
  </div>
  <div class="bet-info">
    <div class="info-title">Payout:</div>
    <div id="Payout" class="info-value">{{ Payout | number : '1.2-2' }}</div>
  </div>
  <div>
      <div class="bet-stake">Stake:</div>
      <div class="bet-stake-field"><input [(ngModel)]="this.bet.Stake" id="Stake" type="number" min="0" max="1000000" (ngModelChange)="calculateStake()" /></div>
  </div>
</div>
