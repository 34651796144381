import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { LeagueResponse} from 'src/app/Types/Response/LeaguesResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'league-list',
  templateUrl: './league-list.component.html',
  styleUrls: ['./league-list.component.scss'],
})
export class LeagueListComponent implements OnDestroy {
  LeaguesResponse: LeagueResponse[] =[];
  NavOpen: Boolean | undefined;
  PriorityLeagues = environment.league;
  @Input() SportId: Number | undefined = 0;
  @Input() SportName: String | undefined = "";

  private subscription!: Subscription;

  toggleNav(){
    this.NavOpen = !this.NavOpen;
  }

  constructor(private sportcastService: SportcastServiceService) {}

  ngOnInit(){
    this.subscription = this.sportcastService.getLeagues(this.SportId).subscribe((res) => {
      this.LeaguesResponse = res.filter(x => x.sportId == this.SportId);
      this.LeaguesResponse = this.leagueSort(this.LeaguesResponse);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public leagueSort(leagues : LeagueResponse[]){
    var array = leagues.sort((a, b) => a.leagueName.localeCompare(b.leagueName));
    let priorityArray: any = []
    let priorityList = this.PriorityLeagues.reverse();

    array.forEach(x => {
      if(priorityList.includes(x.leagueName)) {
        priorityArray.push(array[array.indexOf(x)]);
        array.splice(array.indexOf(x), 1);
      }
    })
    return [...priorityArray, ...array];
  }
}
