import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SportListComponent } from './Sport/sport-list/sport-list.component';
import { SportItemComponent } from './Sport/sport-item/sport-item.component';
import { LeagueListComponent } from './League/league-list/league-list.component';
import { LeagueItemComponent } from './League/league-item/league-item.component';
import { FixtureListComponent } from './Fixture/fixture-list/fixture-list.component';
import { FixtureItemComponent } from './Fixture/fixture-item/fixture-item.component';
import { SportcastServiceService } from './Service/sportcast.service.service';
import {HttpClientModule} from '@angular/common/http';
import { IframeloaderUtilComponent } from './IFrameLoader/iframeloader-util/iframeloader-util.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BetslipFloatingComponent } from './UI/betslip-floating/betslip-floating.component';
import { BetslipComponent } from './control/betslip/betslip.component';
import { BetslipHeaderComponent } from './control/betslip-header/betslip-header.component';
import { BetslipItemsComponent } from './control/betslip-items/betslip-items.component';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { CurrencyPresentPipe } from './pipes/currency-present.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BetslipActionComponent } from './control/betslip-action/betslip-action.component';
import { ToastrModule } from 'ngx-toastr';
@NgModule({
  declarations: [
    AppComponent,
    SportListComponent,
    SportItemComponent,
    LeagueListComponent,
    LeagueItemComponent,
    FixtureListComponent,
    FixtureItemComponent,
    IframeloaderUtilComponent,
    BetslipFloatingComponent,
    BetslipComponent,
    BetslipHeaderComponent,
    BetslipItemsComponent,
    SafeURLPipe,
    CurrencyPresentPipe,
    BetslipActionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule ,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FontAwesomeModule,
    ToastrModule.forRoot()
  ],
  providers: [SportcastServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
