import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FixtureResponse } from 'src/app/Types/Response/FixtureResponse';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';

@Component({
  selector: 'fixture-item',
  templateUrl: './fixture-item.component.html',
  styleUrls: ['./fixture-item.component.scss']
})
export class FixtureItemComponent {
  @Input() isExpanded : Boolean = false;
  @Input() fixture : FixtureResponse |null = null;
  @Input() iFrameURL: string = "";
  @Input() sportName: String | undefined = "";

  IframeOpen = false;

  constructor(
    private eventEmitterService: SportcastServiceService
  ){}

  alterState() {
    this.ToggleIframe(this.fixture, this.iFrameURL);
    this.IframeOpen = true;
  }

  onToggleIframe(open: boolean) {
    this.IframeOpen = open;
  }

  expandRow(){
    this.isExpanded = !this.isExpanded ;
  }

  ToggleSportNav()
  {
    this.eventEmitterService.ToggleSportNavEmitter();
  }

  ToggleIframe(fixtureResponse: FixtureResponse |null = null, url: string)
  {
    this.eventEmitterService.ToggleIframeEmitter(fixtureResponse, url);
  }

  setCurrentSport()
  {
    this.eventEmitterService.setCurrentSportSelected(this.sportName);
  }
}
