import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPresent'
})
export class CurrencyPresentPipe implements PipeTransform {

  transform(num:any): unknown {
    return "R " + new Intl.NumberFormat('en', { maximumSignificantDigits: 3  , minimumSignificantDigits:2}).format(num);
  }

}
