import { EventEmitter, Injectable} from '@angular/core';
import { Observable, catchError, BehaviorSubject, Subscription } from 'rxjs';
import { LeagueResponse } from '../Types/Response/LeaguesResponse';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sport } from '../Types/Response/SportResponse';
import { FixtureResponse } from '../Types/Response/FixtureResponse';
import { Bet, DebitResponse } from '../Types/Models/Bet';
import { ToastrService } from 'ngx-toastr';
import { RequestBetPriceByIdResponse } from '../Types/Response/RequestBetPriceByIdResponse';
@Injectable({
  providedIn: 'root'
})
export class SportcastServiceService{
  APIKey = environment.APIKey;
  slipEp = environment.slipEp;
  ClientId: string = "";
  fixtureId: string | null = null;
  stake: number = 0;
  currentSportSelected: String = "";
  sportToggle: EventEmitter<any> = new EventEmitter();
  sportToggleSub: Subscription = new Subscription();
  iFrameToggle: EventEmitter<any> = new EventEmitter();
  iFrameToggleSub: Subscription = new Subscription();
  betslipToggle: EventEmitter<any> = new EventEmitter();
  betslipToggleSub: Subscription = new Subscription();
  clearBetslip: EventEmitter<any> = new EventEmitter();
  clearBetslipSub: Subscription = new Subscription();
  addBet: EventEmitter<any> = new EventEmitter();
  addBetSub: Subscription = new Subscription();
  removeBet: EventEmitter<any> = new EventEmitter();
  removeBetSub: Subscription = new Subscription();
  togglePlaceBetButton: EventEmitter<any> = new EventEmitter();
  togglePlaceBetButtonSub: Subscription = new Subscription();
  createNewBetList: EventEmitter<any> = new EventEmitter();
  createNewBetListSub: Subscription = new Subscription();
  setBetslipCount: EventEmitter<any> = new EventEmitter();
  setBetslipCountSub: Subscription = new Subscription();
  sportscastApiBaseUrl = environment.sportscastApiBaseUrl;

  constructor(private http : HttpClient, private toastr: ToastrService) { }

  async updateBetSlip(response: any) {
    const betslipObject = JSON.parse(response);
    const betSlipUuid = betslipObject && betslipObject.betslipUid;
    const betslipResponse = await this.http.get<any>(`${this.sportscastApiBaseUrl}/api/Transaction/GetBetSlipData?BetSlipUuid=${betSlipUuid}`).toPromise();

    if (betslipResponse) {
        this.addBet.emit({ BetSlipUid: betslipResponse.betSlipUuid, SportName: this.getCurrentSportSelected(), Match: betslipResponse.fixtureName, Description: betslipResponse.description, Odds: betslipResponse.odds, ClientId: this.ClientId, Stake: this.stake, FixtureID: this.fixtureId });
    } else {
      console.log(`Error on updateBetSlip method. Response from GetBetSlipData call: ${betslipResponse}`);
    }
  }

  public getSports(): Observable<Sport[]> {
    const url = environment.localUrl + environment.sportsRequestUrl;
    return this.http.get<Sport[]>(url);
  }

  public getLeagues(SportId: Number | undefined = 0) : Observable<LeagueResponse[]> {
    const url  = environment.localUrl + environment.leagueRequestUrl ;
    const body = { SportId: SportId}
    return this.http.post<LeagueResponse[]>(url, body) ;
  }

  public getFixture(LeagueId: Number| undefined = 0, fixtureId: string = '') : Observable<FixtureResponse[]>{
    const url = environment.localUrl + environment.fixtureRequestUrl ;
    const body = { LeagueId: LeagueId, fixtureId: fixtureId }
    return this.http.post<FixtureResponse[]>(url, body) ;
  }

  setClientId(ClientId: string) {
      this.ClientId = ClientId;
  }

  setFixtureID(fixtureId: string| null = null){
    this.fixtureId = fixtureId
  }

  public placeBet(bets: Bet[]) :Observable<DebitResponse>{
    const url = environment.transactionUrl + environment.placeBetUrl;
    const body = bets;
    return this.http.post<DebitResponse>(url, body).pipe(
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public BetConfirmation(BetslipUid: string, fixtureId: string): Observable<any> {
    const url = environment.baseUrl + environment.betConfirmationUrl;
    const body = {
      APIKey: this.APIKey,
      BetslipUid,
      fixtureId: this.fixtureId,
      timestamp: new Date().toISOString()
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/json',
        Accept: 'application/json'
      })
    };
    return this.http.post<any>(url, body, options).pipe(
      catchError((error: any) => {
        throw error;
      })
    );
  }

  public setCurrentSportSelected(sportName: String | undefined = "")
  {
    this.currentSportSelected = sportName;
  }

  public getCurrentSportSelected() : String
  {
    return this.currentSportSelected;
  }

  //Toggles Sport List div in app.component.html
  public ToggleSportNavEmitter()
  {
    this.sportToggle.emit();
  }

  public ToggleIframeEmitter(fixtureResponse: FixtureResponse |null = null, url: string)
  {
    this.iFrameToggle.emit({fixture: fixtureResponse, url: url});
  }

  public ToggleBetslipEmitter()
  {
    this.betslipToggle.emit();
  }

  public ClearBetslipEmitter()
  {
    this.clearBetslip.emit();
  }

  public RemoveBetEmitter(index: number)
  {
    this.removeBet.emit(index);
  }

  public TogglePlaceBetButtonEmitter(toggle: boolean)
  {
    this.togglePlaceBetButton.emit(toggle);
  }

  public CreateNewBetListEmitter(bets: Bet[] = [])
  {
    this.createNewBetList.emit(bets);
  }

  public SetBetSlipCountEmitter(count: number)
  {
    this.setBetslipCount.emit(count);
  }

  async updateBetSlipOnPriceOrFixtureChange(betSlipUuid: string) {
    const betslipResponse = await this.http.get<any>(`${this.sportscastApiBaseUrl}/api/Transaction/GetBetSlipData?BetSlipUuid=${betSlipUuid}`).toPromise();

    if (betslipResponse) {
        this.addBet.emit({ BetSlipUid: betslipResponse.betSlipUuid, SportName: this.getCurrentSportSelected(), Match: betslipResponse.fixtureName, Description: betslipResponse.description, Odds: betslipResponse.odds, ClientId: this.ClientId, Stake: this.stake, FixtureID: this.fixtureId });
    } else {
      this.toastr.error("Submit Bet", "An error has occured. Please try again later.");
      console.log(`Error on updateBetSlip method. Response from GetBetSlipData call: ${betslipResponse}`);
    }
  }

public RequestBetPriceById(betsUuid: string[]): Observable<RequestBetPriceByIdResponse> {
  const url = environment.sportscastApiBaseUrl + environment.requestBetPriceByIdUrl;
  const body = betsUuid;
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'text/json',
      Accept: 'application/json'
    })
  };
  return this.http.post<RequestBetPriceByIdResponse>(url, body, options).pipe(
    catchError((error: any) => {
      throw error;
    })
  );
}
}
