import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges , ViewChild, ElementRef, OnDestroy  } from '@angular/core';
import { FixtureResponse } from 'src/app/Types/Response/FixtureResponse';
import { environment } from 'src/environments/environment';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { Bet } from 'src/app/Types/Models/Bet';
@Component({
  selector: 'iframeloader-util',
  templateUrl: './iframeloader-util.component.html',
  styleUrls: ['./iframeloader-util.component.scss']
})
export class IframeloaderUtilComponent implements OnDestroy  {
  @Input() fixture: FixtureResponse | null = null;
  @Input() IframeOpen: boolean = false;
  @Output() toggleIframe: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() iFrameURL: string = '';
  @ViewChild('widgethost') widgethost!: ElementRef;

  @Input() Bets: Bet[] = [];
  @Input() ClientId: string = '';

  constructor(private sportcastService: SportcastServiceService) {
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  ngOnInit()
  {
    this.sportcastService.iFrameToggleSub = this.sportcastService.iFrameToggle.subscribe((params: any) =>{
      this.IframeOpen = true;
      this.fixture = params.fixture;
      this.iFrameURL = params.url;
      this.iFrameURL = `${environment.iFrameURL}${params.fixture.fixtureId}`;
    });
  }

  onToggleIframe(open: boolean) {
    this.toggleIframe.emit(open);
    this.IframeOpen = open;
  }

  handleMessage(message: MessageEvent) {
      var iframe = document.getElementById("widgethost");

      if(iframe != null)
      {
        var iWindow = (<HTMLIFrameElement> iframe).contentWindow;
        if (message.source === iWindow) {
          const response = (message.data);
          this.sportcastService.updateBetSlip(response);
          this.sportcastService.setFixtureID(this.fixture?.fixtureId);
        }
      }

      if (environment.allowedPlatformUrls.includes(message.origin)) {
        if (message.data && message.data.punterId) {
          const punterId = message.data.punterId;
          this.sportcastService.setClientId(punterId);
        }
      }
    }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleMessage);
  }
}
