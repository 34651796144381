import { Component } from '@angular/core';
import { Bet, DebitResponse } from 'src/app/Types/Models/Bet';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RequestBetPriceByIdResponse, RequestBetPriceByIdResponseData } from 'src/app/Types/Response/RequestBetPriceByIdResponse';
import { BetUpdateType } from 'src/app/Types/Enums/BetUpdateType';

@Component({
  selector: 'app-betslip-action',
  templateUrl: './betslip-action.component.html',
  styleUrls: ['./betslip-action.component.scss']
})
export class BetslipActionComponent {

  @Input() Bets: Bet[] = [];
  @Input() fixtureName: string = '';
  @Input() BetSlipUid: string = '';
  @Input() ClientId: string = '';
  @Input() Stake: number = 0;
  @Input() Odds: number = 0;
  @Input() Description: string = "";
  @Input() fixtureId: string = '';
  @Input() Payout: number = 0;
  isPlaceBetEnabled: boolean = false;
  updatedBetsDisplayMessage: string[] = [];
  requestBetPriceByIdResponse: RequestBetPriceByIdResponse | undefined;

  constructor(private sportcastService: SportcastServiceService, private toastr: ToastrService) {
    this.sportcastService.togglePlaceBetButtonSub = this.sportcastService.togglePlaceBetButton.subscribe((toggle: boolean) => {
      this.TogglePlaceBetButton(toggle);
    });
  }

  placeBet(): void {
    const successfulBets: Bet[] = [];
    const unsuccessfulBets: Bet[] = [];
    const betUuids: string[] = [];
    this.updatedBetsDisplayMessage = [];

    for (var i = 0; i < this.Bets.length; ++i) {
      var isError = false;
      if (!this.Bets[i].Stake) {
        isError = true;
        this.toastr.error("No Stake Entered", "Please enter a stake for your bets and then submit.");
      }
      else if (this.Bets[i].Stake <= 0) {
        isError = true;
        this.toastr.error("Incorrect Stake Entered", "Please add an appropriate amount to you stake");
      }

      if ((this.Bets[i].Stake * this.Bets[i].Odds) >= environment.maxPayout) {
        isError = true;
        this.toastr.error("Max payout Exceeded", "Please lower your stake.");
      }

      if (!isError) {
        successfulBets.push(this.Bets[i]);
        betUuids.push(this.Bets[i].BetSlipUid);
      }
      else {
        unsuccessfulBets.push(this.Bets[i]);
      }
    }

    if (successfulBets.length > 0) {
      this.validateBetslip(betUuids).then(() => {
        if (this.requestBetPriceByIdResponse?.status.toLowerCase() === 'success') {
          const requiredBetUpdates: RequestBetPriceByIdResponseData[] = JSON.parse(this.requestBetPriceByIdResponse?.data + '')

          if (requiredBetUpdates?.length === 0) {
            this.sportcastService.placeBet(successfulBets).subscribe((response: DebitResponse) => {
              if (response?.status.toLowerCase() === 'success') {
                this.toastr.success("Submit Bet", "Success");
                this.sportcastService.CreateNewBetListEmitter(unsuccessfulBets);
              } else {
                this.toastr.error("Submit Bet", "Unable to place bet. Please try again later.");
              }
            },
              (error) => {
                this.toastr.error("Submit Bet", error.message);
                throw error;
              });
          } else {
            requiredBetUpdates.forEach(element => {
              const betIndex = this.Bets.findIndex(x => x.BetSlipUid === element.BetSlipUid);
              if (element.UpdateType === BetUpdateType.ExpiredFixture) {
                if (betIndex > -1) {
                  this.sportcastService.RemoveBetEmitter(betIndex);
                }
                this.updatedBetsDisplayMessage.push(`${element.FixtureName} removed from betslip due to expired fixture.`);
              }
              if (element.UpdateType === BetUpdateType.ChangedOdds) {
                // this.sportcastService.updateBetSlipOnPriceOrFixtureChange(element.BetSlipUid);
                if (betIndex > -1) {
                  this.Bets[betIndex].Odds = element.NewOdd;
                }
                this.updatedBetsDisplayMessage.unshift(`${element.FixtureName} odds have been updated from ${element.OldOdd} to ${element.NewOdd}.`);
              }
            });
            this.sportcastService.CreateNewBetListEmitter(this.Bets);
          }
        } else {
          this.toastr.error("Submit Bet", "Unable to place bet. Please try again later.");
        }
      })
    }
  }

  TogglePlaceBetButton(toggle: boolean) {
    this.isPlaceBetEnabled = toggle;
  }

  validateBetslip(betUuids: string[]) {
    let promise = new Promise((resolve) => {
      this.sportcastService.RequestBetPriceById(betUuids).subscribe((response: RequestBetPriceByIdResponse) => {
        this.requestBetPriceByIdResponse = response;
        resolve(response)
      });
    });
    return promise;

  }

}
