import { configuration } from "src/app/Types/Config/configuration";

export const environment:configuration = {
  APIKey: '115b5418-ef78-4dad-839a-1e673b67f204',
  iFrameURL: "https://public-prod-gen2.sportcastlive.com/markets?key=115b5418-ef78-4dad-839a-1e673b67f204&FixtureId=",
  slipEp: 'https://cluster.sportcastlive.com/api/getbetslipscrolleritems?key=115b5418-ef78-4dad-839a-1e673b67f204',
  localUrl: "https://sportcast-api.Hollywoodbets.net/api/Sport/",
  transactionUrl: "https://sportcast-api.Hollywoodbets.net/api/Transaction/",
  baseUrl: "https://cluster.sportcastlive.com/",
  sportsRequestUrl: "GetAll",
  leagueRequestUrl: "GetLeagues",
  fixtureRequestUrl: "GetFixtures",
  betConfirmationUrl: "/Public/BetConfirmation",
  betRequestUrl: '/api/bets',
  placeBetUrl: 'Debit',
  requestBetPriceByIdUrl: '/api/Transaction/RequestBetPriceById',
  sportscastApiBaseUrl: "https://sportcast-api.hollywoodbets.net",
  allowedPlatformUrls : ["https://m.hollywoodbets.net", "https://www.hollywoodbets.net", "https://new.hollywoodbets.net", "https://www.datafree.hollywoodbets.net", "https://datafree-origin.hollywoodbets.net"],
  maxPayout: 1000000, 
  league: ["English Premier League", "Spanish Primera Liga", "German Bundesliga", "French Ligue 1", "Italian Serie A"]
};
