import { Component, Input } from '@angular/core';
import { LeagueResponse } from 'src/app/Types/Response/LeaguesResponse';

@Component({
  selector: 'league-item',
  templateUrl: './league-item.component.html',
  styleUrls: ['./league-item.component.scss']
})
export class LeagueItemComponent {
    isExpanded : Boolean = false ;
    @Input() league : LeagueResponse |null = null  ;
    @Input() sportName: String | undefined = "";

    expandRow(){
      this.isExpanded = !this.isExpanded ;
    }

    ngOnInit(){}
}
