import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { Bet } from 'src/app/Types/Models/Bet';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-betslip-header',
  templateUrl: './betslip-header.component.html',
  styleUrls: ['./betslip-header.component.scss']
})
export class BetslipHeaderComponent {
  @Output() removeAllBetsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeBetslip: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() Bets: Bet[] = [];
  isPlaceBetEnabled: boolean = false;
  faTimes = faTimes;

  constructor(private sportcastService: SportcastServiceService)
  {
  }


  removeAllBets() {
    this.removeAllBetsEvent.emit();
    this.Bets = [];
    this.sportcastService.ClearBetslipEmitter();
  }

  onCloseBetslip() {
    this.closeBetslip.emit(true);
  }
}
