import { Component, Input, OnInit, Output } from '@angular/core';
import { trigger , state , style , transition , animate } from '@angular/animations';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { Bet } from 'src/app/Types/Models/Bet';

@Component({
  selector: 'app-betslip',
  templateUrl: './betslip.component.html',
  styleUrls: ['./betslip.component.scss'],
  animations:[

    trigger('toggleBetslip' , [
      state("open" , style({
        top : '50%'
      })),
      state("closed",style({
        top: '-100vh'
      })) ,
      transition('open => closed',[
        animate('0.1s')
      ]),
      transition('closed => open',[
        animate('0.1s')
      ])
    ])
  ]
})
export class BetslipComponent {
  @Input() showBetslip: boolean = false;
  @Input() closeBetslip = false;
  @Input() Bets: Bet[] = [];

  constructor(private sportcastService: SportcastServiceService){
    this.sportcastService.betslipToggleSub = this.sportcastService.betslipToggle.subscribe(() =>{
      this.toggleBetslip();
    });

    this.sportcastService.addBetSub = this.sportcastService.addBet.subscribe((data?: any) => {
      this.AddBet(data);
    });

    
    this.sportcastService.clearBetslipSub = this.sportcastService.clearBetslip.subscribe(() =>{
      this.clearForm();
    });

    this.sportcastService.removeBetSub = this.sportcastService.removeBet.subscribe((index: number) =>{
      this.RemoveBet(index);
    });

    this.sportcastService.createNewBetListSub = this.sportcastService.createNewBetList.subscribe((bets: Bet[] = []) =>{
      this.CreateNewBetList(bets);
    });
  }

  toggleBetslip(){
    this.showBetslip = !this.showBetslip;
  }

  onCloseBetslip() {
    this.closeBetslip = true;
    this.showBetslip = false;
  }

  AddBet(data?: any)
  {
    if(data != undefined)
    {
      for(var i = 0; i < this.Bets.length; ++i)
      {
        if(this.Bets[i].BetSlipUid == data.BetSlipUid)
        {
          return;
        }
      }

      (<HTMLButtonElement> document.getElementById("placebet")).disabled = false;
      
      this.Bets.push({ BetSlipUid: data.BetSlipUid, SportName: data.SportName, Description: data.Description, ClientId: data.ClientId, Stake: data.Stake, Odds: data.Odds, FixtureName: data.Match, FixtureID: data.FixtureID, Timestamp: new Date().toJSON() });

      this.sportcastService.TogglePlaceBetButtonEmitter(true);

      this.sportcastService.SetBetSlipCountEmitter(this.Bets.length);
    }
  }

  clearForm(): void {
    this.Bets = [];

    this.sportcastService.TogglePlaceBetButtonEmitter(false);

    this.sportcastService.SetBetSlipCountEmitter(this.Bets.length);
  }

  RemoveBet(index: number): void
  {
    this.Bets.splice(index, 1);

    if(this.Bets.length <= 0)
    {
      this.sportcastService.TogglePlaceBetButtonEmitter(false);
    }

    this.sportcastService.SetBetSlipCountEmitter(this.Bets.length);
  }

  CreateNewBetList(newBets: Bet[] = []) : void
  {
    this.Bets = newBets;

    if(this.Bets.length <= 0)
    {
      this.sportcastService.TogglePlaceBetButtonEmitter(false);
    }
    
    this.sportcastService.SetBetSlipCountEmitter(this.Bets.length);
  }

  GetBets(): Bet[]
  {
    return this.Bets;
  }
}
