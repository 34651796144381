import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { trigger , state , style , transition , animate } from '@angular/animations';
import { Bet } from 'src/app/Types/Models/Bet';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';

@Component({
  selector: 'app-betslip-floating',
  templateUrl: './betslip-floating.component.html',
  styleUrls: ['./betslip-floating.component.scss'],
  animations:[
    trigger('toggleBetslipPill' , [

      state("open",style({
        bottom : '0px'
      })),
      state("closed" , style({
        bottom: '-100vh'
      })),
      transition('open => closed',[
        animate('0.2s')
      ]),
      transition('closed => open',[
        animate('0.2s')
      ])
    ])
  ]
})

export class BetslipFloatingComponent implements OnInit {
  @Output() toggleBetslip: EventEmitter<void> = new EventEmitter<void>();
  isBetslipOpen : Boolean = true;
  betSlipCount: number = 0;
  previousBetCount = 0;

  constructor(private sportCastService: SportcastServiceService) 
  {
    this.sportCastService.setBetslipCountSub = this.sportCastService.setBetslipCount.subscribe((count: number) =>{
      this.SetBetslipCount(count);
    });
  }

  ngOnInit(): void {
  }

  onViewBetSlip(): void {
    this.toggleBetslip.emit();
  }

  SetBetslipCount(count: number) {
    this.betSlipCount = count;
  }
}
