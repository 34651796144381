<div (click)="expandRow()" class="league-item-container">
  <div class="league-name">{{league?.leagueName}}</div>
  <div *ngIf="!isExpanded; then openBlock else closeBlock" class="league-dd-ico"></div>

  <ng-template #openBlock>
    <div class="league-dd-ico">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    </div>
  </ng-template>

  <ng-template #closeBlock>
    <div class="league-dd-ico">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
    </div>
  </ng-template>
</div>


<div *ngIf="isExpanded; then showBlock else hideBlock" class="league-to-fixture-relation">
</div>
<ng-template #showBlock>
  <fixture-list [LeagueId]="league?.leagueId" [SportName]="sportName">

  </fixture-list>
</ng-template>
<ng-template #hideBlock>

</ng-template>


