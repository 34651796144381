import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { FixtureResponse, PayLoad } from 'src/app/Types/Response/FixtureResponse';

@Component({
  selector: 'fixture-list',
  templateUrl: './fixture-list.component.html',
  styleUrls: ['./fixture-list.component.scss']
})
export class FixtureListComponent implements OnInit, OnDestroy{
  Fixtures: FixtureResponse[] = [];
  NavOpen = true;
  @Input() LeagueId: Number | undefined = 0;
  @Input() SportName: String | undefined = "";

  private subscription: Subscription | undefined;

  toggleNav(){
    this.NavOpen = !this.NavOpen;
  }

  constructor(private sportcastService: SportcastServiceService) {}

  ngOnInit(){
    this.subscription = this.sportcastService.getFixture(this.LeagueId).subscribe((res) => {
      this.Fixtures = res.filter(x => x.leagueId == this.LeagueId);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
