import { Component, Input, OnInit} from '@angular/core';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { Bet } from 'src/app/Types/Models/Bet';
import { faClose } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-betslip-items',
  templateUrl: './betslip-items.component.html',
  styleUrls: ['./betslip-items.component.scss']
})
export class BetslipItemsComponent implements OnInit  {
  faClose = faClose;

  @Input() betIndex!: number;
  @Input() bet!: Bet;
  @Input() Bets: Bet[] = [];
  @Input() Payout: number = 0;
  isPlaceBetEnabled: boolean = false;

  constructor(private sportcastService: SportcastServiceService) {
  }

  ngOnInit(): void {
  }

  calculateStake()
  {
    this.Payout = this.bet.Stake * this.bet.Odds;
  }

  RemoveBet()
  {
    this.sportcastService.RemoveBetEmitter(this.betIndex);
  }
}
