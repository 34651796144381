import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription} from 'rxjs';
import { SportcastServiceService } from 'src/app/Service/sportcast.service.service';
import { Sport } from 'src/app/Types/Response/SportResponse';

@Component({
  selector: 'sport-list',
  templateUrl: './sport-list.component.html',
  styleUrls: ['./sport-list.component.scss'],
})
export class SportListComponent implements OnInit, OnDestroy{
  SportsAvailable: Sport[] = [];
  @Input() NavOpen: Boolean | undefined;

  private subscription: Subscription | undefined;

  constructor(private sportcastService: SportcastServiceService) {}

  toggleNav(){
    this.NavOpen = !this.NavOpen;
  }

  ngOnInit() : void{
    this.subscription = this.sportcastService.getSports().subscribe((res) => {
      this.SportsAvailable = res;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
